<template>
  <div class="quotation">
    <div class="title">{{ $t("lang.newQuote") }}</div>
    <div class="typeList">
      <transition
        v-for="(item, index) in quotationTypeList"
        :key="item.dictValue"
        :name="showType[index] ? 'active' + index : 'quotationType' + index"
      >
        <div
          class="type-icon-main"
          @click="clickType(index, item)"
          v-show="item.show"
          :style="{ left: 1.25 * index + 'rem' }"
          :ref="'quotationType' + index"
          :class="{ noneType: index > 0 }"
        >
          <svg-icon :icon-class="item.cssClass" class="svg-icon"></svg-icon>
          <div class="type-title">{{ item.dictLabel }}</div>
        </div>
      </transition>
      <div
        v-for="(item, index) in quotationTypeList"
        :key="item.dictValue"
        class="type-item"
        v-show="showType2[index]"
      >
        <div class="type-icon-main">
          <svg-icon :icon-class="item.cssClass" class="svg-icon"></svg-icon>
          <div class="type-title">{{ item.dictLabel }}</div>
        </div>
        <transition name="hoverDiv">
          <div class="children" v-show="showType2[index]">
            <div class="radio">
              <el-radio
                v-model="quotationType"
                :label="item2.dictValue"
                v-for="item2 in item.children"
                :key="item2.dictValue"
                class="radio-item"
              >
                <svg-icon :icon-class="item2.dictValue"></svg-icon
                >{{ item2.dictValue }} </el-radio
              ><svg-icon
                icon-class="left"
                class="left-icon"
                @click="close"
              ></svg-icon>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="Quote">
      <el-button type="primary" v-show="showQuote" @click="quote()">{{
        $t("lang.QText85")
      }}</el-button>
    </div>

    <div class="or">{{ $t("lang.BText76") }}</div>
    <div class="otherList">
      <div class="type1" @click="showFillIn('q')">
        {{ $t("lang.QText5") }}
        <svg-icon icon-class="into"></svg-icon>
      </div>
      <div class="type1" @click="showFillIn('b')">
        {{ $t("lang.QText6") }}
        <svg-icon icon-class="into"></svg-icon>
      </div>
      <div class="type1" @click="showFillIn('qt')">
        {{ $t("lang.QText7") }}
        <svg-icon icon-class="into"></svg-icon>
      </div>
    </div>

    <el-dialog
      v-if="fillInFlag"
      :visible.sync="fillInFlag"
      class="fillInDialog"
      width="40%"
      v-loading="fillInLoading"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="fillInHeader">
        <div class="headerTitle">{{ $t("lang.BText114") }}</div>
        <div class="close" @click="fillInFlag = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <fill-in-list
        :defaultTab="defaultTab"
        @Manage="Manage"
        @fillInQuo="fillInQuo"
      ></fill-in-list>
    </el-dialog>
    <el-dialog
      v-if="createFlag"
      :visible.sync="createFlag"
      class="createDialog dw-dialog"
      width="70%"
      v-loading="createLoading"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ dialogTitle }}</div>
        </div>

        <div class="close" @click="openCheckBox()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <create
          :quotationMainType="quotationMainType"
          :quotationType="quotationType"
          @submit="submitQuo"
          @next="toTop"
          v-if="showCreate"
        ></create>
        <review
          v-if="showReview"
          :quotationData="quotationData"
          @edit="clickEdit"
          @cancelReview="showDelect = true"
        ></review>
        <edit
          v-if="showEdit"
          :quotationData="JsonQuoData"
          @cancelEdit="cancelEdit"
          @editReview="editReview"
        ></edit>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showDelect"
      class="closeDialog"
      width="30%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="close" @click="showDelect = false">
        <svg-icon class="close-icon" icon-class="close"></svg-icon>
      </div>
      <div class="line">{{ $t("lang.NText190") }}</div>
      <div class="line">
        {{ $t("lang.NText191") }}
      </div>
      <div class="bottom-button">
        <dw-button @click="showDelect = false" type="infoSmall"
          ><span>{{ $t("lang.QText79") }}</span></dw-button
        >
        <dw-button type="primarySmall" @click="closeCreate()"
          ><span>{{ $t("lang.NText192") }}</span></dw-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Create from "./create.vue";
import fillInList from "./fillInList.vue";
import dwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import Review from "./Review.vue";
import Edit from "./edit.vue";

export default {
  components: { fillInList, Create, dwCheckBox, Review, Edit },
  data() {
    return {
      quotationMainType: "",
      quotationType: "",
      quotationTypeList: [],
      review: "",
      fillInLoading: false,
      fillInFlag: false,
      defaultTab: "0",
      showType: [false, false, false, false],
      showType2: [false, false, false, false],
      showQuote: false,
      createFlag: false,
      createLoading: false,
      showDelect: false,
      showReview: false,
      showCreate: false,
      showEdit: false,
      quotationData: "",
      JsonQuoData: {},
      fillIn: false,
    };
  },
  created() {
    let that = this;
    this.$session("quotationType").get((value) => {
      that.quotationTypeList = value;
      that.quotationTypeList.forEach((element) => {
        element.show = true;
      });
    });
  },
  methods: {
    toTop() {
      this.$refs.dwDialogMain.scrollTo(0, 0);
    },
    clickType(e, item) {
      if (e > 0) {
        return;
      }
      this.showType[e] = true;
      this.quotationMainType = item.dictValue;
      this.quotationTypeList.forEach((item, index) => {
        if (index == e) {
          this.quotationType = item.children[0].dictValue;
          if (e != 0) {
            setTimeout(() => {
              this.quotationTypeList[index].show = false;
              this.$forceUpdate();
            }, 600);
          }
          setTimeout(() => {
            this.showType2[e] = true;
            this.showQuote = true;
            this.$forceUpdate();
          }, 950);
        } else if (index != e) {
          this.quotationTypeList[index].show = false;
          this.showType2[e] = false;
          this.$forceUpdate();
        }
      });
    },
    clickEdit() {
      this.showDelect = false;
      this.showReview = false;
      this.showEdit = true;
      this.showQuote = false;
      this.toTop();
    },
    cancelEdit() {
      if (this.fillIn) {
        this.openCheckBox();
      } else {
        this.clickReview();
      }
    },
    clickReview() {
      this.showDelect = false;
      this.showReview = true;
      this.showEdit = false;
      this.showQuote = false;
    },
    closeCreate() {
      this.showDelect = false;
      this.createFlag = false;
      this.showReview = false;
      this.showEdit = false;
    },
    editReview(e) {
      this.quotationData = JSON.stringify(e.submitForm);
      this.clickReview();
      this.toTop();
    },
    close() {
      this.showType = [false, false, false, false];
      this.showType2 = [false, false, false, false];
      this.showQuote = false;
      this.quotationTypeList.forEach((item, index) => {
        this.quotationTypeList[index].show = true;
      });
    },
    quote() {
      let that = this;
      if (that.quotationType == "") {
        that.$notify({
          title: this.$t("lang.SText126"),
          type: "warning",
          offset: 100,
        });
        return;
      }
      if (that.quotationType == "FCL") {
        that.createFlag = true;
        that.showCreate = true;
        that.close();
        that.dialogTitle = this.$t("lang.NText189");
        return;
      }
      that.$notify({
        title: this.$t("lang.SText127"),
        type: "warning",
        offset: 100,
      });
      return;
    },
    showFillIn(e) {
      if (e == "q") {
        this.defaultTab = "0";
      }
      if (e == "b") {
        this.defaultTab = "1";
      }
      if (e == "qt") {
        this.defaultTab = "2";
      }
      this.fillInFlag = true;
    },
    Manage(e) {
      console.log(e);
      this.fillInFlag = false;
      if (e == 0) {
        this.$router.push({ path: "/Quotation/quotation" });
      }
      if (e == 1) {
        this.$router.push({ path: "/Booking" });
      }
      if (e == 2) {
        this.$router.push({ path: "/Quotation/quotationTemplate" });
      }
    },
    openCheckBox() {
      this.showDelect = true;
    },
    submitQuo(data) {
      this.showCreate = false;
      this.showReview = true;
      this.dialogTitle = this.$t("lang.NText188");
      this.JsonQuoData = JSON.parse(JSON.stringify(data.submitForm));
      this.quotationData = JSON.stringify(data.submitForm);
      this.toTop();
    },
    fillInQuo(e) {
      this.bookingId = "";
      this.showEdit = false;
      this.fillInLoading = true;
      console.log(e.quotation);
      if (e.fillIn) {
        this.fillIn = true;
      }
      if (e.quotation) {
        setTimeout(() => {
          this.JsonQuoData = JSON.parse(JSON.stringify(e.quotation));
          this.quotationData = JSON.stringify(e.quotation);
          this.fillInFlag = false;
          this.createFlag = true;
          this.showEdit = true;
          this.dialogTitle = this.$t("lang.NText189");
          this.showCreate = false;
          this.showReview = false;
          this.fillInLoading = false;
        }, 200);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/base.scss";
.quotation {
  width: 900px;
  height: auto;
  color: $mainColor;

  .quotationType0-enter-active,
  .quotationType0-leave-active {
    transition: opacity 0.3s;
  }
  .quotationType0-enter,
  .quotationType0-leave-to {
    opacity: 0;
  }

  .quotationType1-enter-active,
  .quotationType1-leave-active {
    transition: opacity 0.6s;
  }
  .quotationType1-enter,
  .quotationType1-leave-to {
    opacity: 0;
  }

  .quotationType2-enter-active,
  .quotationType2-leave-active {
    transition: opacity 0.9s;
  }
  .quotationType2-enter,
  .quotationType2-leave-to {
    opacity: 0;
  }
  .quotationType3-enter-active,
  .quotationType3-leave-active {
    transition: opacity 1.2s;
  }
  .quotationType3-enter,
  .quotationType3-leave-to {
    opacity: 0;
  }

  .active1-enter-active,
  .active1-leave-active {
    transition: transform 0.35s;
  }
  .active1-enter,
  .active1-leave-to {
    transform: translate(-220px, 0);
  }

  .active2-enter-active,
  .active2-leave-active {
    transition: transform 0.35s;
  }
  .active2-enter,
  .active2-leave-to {
    transform: translate(-440px, 0);
  }
  .active3-enter-active,
  .active3-leave-active {
    transition: transform 0.35s;
  }
  .active3-enter,
  .active3-leave-to {
    transform: translate(-660px, 0);
  }

  .hoverDiv-enter-active,
  .hoverDiv-leave-active {
    transition: opacity 0.5s;
  }
  .hoverDiv-enter,
  .hoverDiv-leave-to {
    opacity: 0;
  }

  .createDialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      display: none;
    }
    .dialog-header {
      height: 105px;
      padding: 20px;
      background-color: $mainColor;
      display: flex;
      .dw-logo {
        width: 70px;
        height: 70px;
        padding: 15px;
        .svg-icon {
          width: 70px;
          height: 70px;
          color: #fff;
        }
      }
      .close {
        width: 50px;
        height: 50px;
        margin-top: 30px;
        margin-left: 100px;
        position: absolute;
        top: 12px;
        right: 50px;
        background-color: $mainColor;
        color: #fff;
        padding: 4px;
        cursor: pointer;
        .close-icon {
          width: 50px;
          height: 50px;
          fill: #fff;
          stroke: #fff;
        }
      }

      .dialog-title {
        color: #fff;
        margin-left: 90px;
        text-align: left;
        .row1 {
          margin-top: 5px;
        }
        .row2 {
          margin-top: 14px;
          color: $fontColor1;
        }
      }
      .fill-in {
        width: 330px;
        margin-left: 60px;
        text-align: left;
        cursor: pointer;
        .fill-in-title {
          font-size: 30px;
          color: $background2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;

          .svg-icon {
            height: 15px;
            width: 15px;
            color: #fff;
          }
        }
        .text {
          color: $fontColor1;
          line-height: 20px;
          margin-top: 6px;
          padding-bottom: 20px;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  .closeDialog {
    top: 200px;
    font-size: 20px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 30px;
    }
    .el-dialog__close {
      display: none;
    }
    .close {
      display: flex;
      justify-content: end;
      top: 12px;
      color: #fff;
      cursor: pointer;
      .close-icon {
        width: 25px;
        height: 25px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
    .line {
      margin: 30px 0;
      text-align: left;
      line-height: 30px;
      font-size: 20px;
      color: $mainColor;
    }
    .bottom-button {
      display: flex;
      justify-content: end;
      z-index: 9999;
      .primarySmall {
        margin-left: 10px;
      }
    }
  }

  .Quote {
    display: flex;
    text-align: center;
    justify-content: end;
    margin-right: 100px;
  }

  .title {
    font-size: 30px;
    color: $mainColor;
    line-height: 64px;
    text-align: left;
  }
  .typeList {
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 30px;
    // flex-wrap: wrap;
    position: relative;
    height: 200px;
    width: 900px;

    .type-icon-main {
      height: 200px;
      width: 200px;
      border: 2px solid $mainColor;
      position: absolute;
      margin-bottom: 10px;
      margin-right: 21px;
      .hoverType {
        background-color: $background;
        z-index: 99 !important;
      }

      .svg-icon {
        width: 100px;
        height: 100px;
        padding-top: 28px;
        color: $mainColor;
      }
      .type-title {
        font-size: 18px;
        color: $mainColor;
        margin-top: 20px;
        line-height: 18px;
      }
    }
    .noneType {
      border: 2px solid $fontColor1;
      .type-title {
        color: $fontColor1 !important;
      }
      .svg-icon {
        fill: $fontColor1;
        stroke: $fontColor1;
      }
    }
  }
  .type-item {
  }

  .children {
    position: absolute;
    left: 220px;
    right: 0;
    .radio {
      display: flex;
      align-items: center;
      height: 150px;
      .radio-item {
        margin: 0 20px;
      }
      .svg-icon {
        height: 100px;
        width: 100px;
        margin-right: 10px;
        margin-left: 20px;
      }
      .left-icon {
        top: 30px;
        height: 50px;
        width: 50px;
        padding: 7px;
        color: #c0c0c0;
        border-radius: 32px;
        margin-left: 20px;
        position: absolute;
        right: 0;
        fill: #c0c0c0;
        stroke: #c0c0c0;
      }
      .left-icon:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .or {
    width: 100%;
    border-bottom: 2px solid $mainColor;
    line-height: 60px;
    font-size: 30px;
    text-align: left;
    padding-left: 20px;
  }

  .otherList {
    text-align: left;
    line-height: 80px;
    font-size: 20px;
    width: 420px;
    .type1 {
      padding: 0 20px;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;

      .svg-icon {
        margin-left: 40px;
      }
    }
  }

  .fillInDialog {
    padding-top: 20px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      .fillInHeader {
        height: 100px;
        display: flex;
        justify-content: space-between;
      }
      .headerTitle {
        font-size: 30px;
        color: $mainColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        margin-left: 20px;
        line-height: 100px;
      }
      .close {
        width: 30px;
        height: 30px;
        margin-top: 40px;
        margin-right: 30px;

        cursor: pointer;
        .close-icon {
          width: 30px;
          height: 30px;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
    }
  }
}
</style>
