<template>
  <div class="qfillInList">
    <k-second-header
      :clickchange="false"
      :data="settingList"
      @handleSelect="handleSelect"
      :activeIndex="defaultTab"
    ></k-second-header>
    <div class="headeBbutton">
      <div class="refresh" @click="query">
        <svg-icon icon-class="refresh"></svg-icon>
        {{ $t("lang.BText60") }}
      </div>
      <div class="refresh" @click="Manage">
        <svg-icon icon-class="setting"></svg-icon>
        {{ $t("lang.BText61") }}
      </div>
    </div>
    <template v-if="tab == 0">
      <dwlist
        :url="source.quotation_api.quotationListData"
        :query-params="quoQueryForm"
        :showHeader="false"
        cellClassName="fillRow"
        ref="quoList"
        @row-click="getAQuote"
      >
        <el-table-column prop="summry" :label="$t('lang.BText4')">
          <template slot-scope="scope">
            <div class="type">
              {{ quotationTypeObj[scope.row.quotationMainType] }} -
              {{ quotationTypeObj[scope.row.quotationType] }}
            </div>
            <div class="summry">
              <span v-if="scope.row.fromType == 0">{{
                scope.row.fromCyPortName
              }}</span>
              <span v-if="scope.row.fromType == 1"
                >{{ scope.row.fromDoorCityName }},{{
                  scope.row.fromDoorCountry
                }}</span
              >
              <span style="color: #299be4"> -> </span>
              <span v-if="scope.row.toType == 0">{{
                scope.row.toCyPortName
              }}</span>
              <span v-if="scope.row.toType == 1"
                >{{ scope.row.toDoorCityName }},{{
                  scope.row.toDoorCountry
                }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreateDate" :label="$t('lang.BText5')">
          <template slot-scope="scope">
            <div
              class="CreateDate"
              v-if="$i18n.locale == 'zh_cn' && scope.row.createDate !== ''"
            >
              {{ $t("lang.BText62") }}：{{ scope.row.createDate.slice(0, 10) }}
            </div>
            <div
              class="CreateDate"
              v-if="$i18n.locale == 'en_us' && scope.row.createDate !== ''"
            >
              {{ $t("lang.BText62") }}：
              {{ utils.zhDate_to_enDate(scope.row.createDate).slice(0, 15) }}
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </template>
    <template v-if="tab == 1">
      <dwlist
        :key="Math.ceil(Math.random() * 100000)"
        :url="source.booking_api.bookingList"
        :query-params="bookingQueryForm"
        :showHeader="false"
        cellClassName="fillRow"
        ref="bookingList"
        @row-click="getABooking"
      >
        <el-table-column prop="summry" :label="$t('lang.BText4')">
          <template slot-scope="scope">
            <div
              class="type"
              v-if="quotationTypeObj[scope.row.bookingType] == 'FCL'"
            >
              {{ $t("lang.QText1") }}-{{
                quotationTypeObj[scope.row.bookingType]
              }}
            </div>
            <div
              class="type"
              v-if="quotationTypeObj[scope.row.bookingType] == 'LCL'"
            >
              {{ $t("lang.QText1") }}-{{
                quotationTypeObj[scope.row.bookingType]
              }}
            </div>
            <div class="port">
              {{ scope.row.originPortName
              }}<svg-icon icon-class="right" style="color: #299be4"></svg-icon
              >{{ scope.row.destinationDischargePortName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreateDate" :label="$t('lang.BText5')">
          <template slot-scope="scope">
            <div
              class="CreateDate"
              v-if="$i18n.locale == 'zh_cn' && scope.row.createDate !== ''"
            >
              {{ $t("lang.BText62") }}：{{ scope.row.createDate.slice(0, 10) }}
            </div>
            <div
              class="CreateDate"
              v-if="$i18n.locale == 'en_us' && scope.row.createDate !== ''"
            >
              {{ $t("lang.BText62") }}：
              {{ utils.zhDate_to_enDate(scope.row.createDate).slice(0, 15) }}
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </template>
    <template v-if="tab == 2">
      <div class="templateList">
        <dwlist
          :url="source.quotation_api.findTemplateList"
          :query-params="temQueryForm"
          :showHeader="false"
          cellClassName="fillRow"
          ref="quoTList"
          @row-click="getAQuote"
        >
          <el-table-column
            prop="quotationTemplateName"
            :label="$t('lang.BText4')"
          >
            <template slot-scope="scope">
              <div class="line1">
                {{ scope.$index + 1 }}. {{ scope.row.quotationTemplateName }}
              </div>
              <div class="line2">
                {{ quotationTypeObj[scope.row.quotationMainType] }} -
                {{ quotationTypeObj[scope.row.quotationType] }} |
                <span v-if="scope.row.fromType == 0">{{
                  scope.row.fromCyPortName
                }}</span>
                <span v-if="scope.row.fromType == 1"
                  >{{ scope.row.fromDoorCityName }},{{
                    scope.row.fromDoorCountry
                  }}</span
                >
                <span style="color: #299be4"> -> </span>
                <span v-if="scope.row.toType == 0">{{
                  scope.row.toCyPortName
                }}</span>
                <span v-if="scope.row.toType == 1"
                  >{{ scope.row.toDoorCityName }},{{
                    scope.row.toDoorCountry
                  }}</span
                >
                <span class="line3" v-if="scope.row.containerDatas !== ''">
                  <span
                    v-for="(item, index) in JSON.parse(
                      scope.row.containerDatas
                    )"
                    :key="index"
                  >
                    {{ item.containerSize }}*{{ item.count }}
                  </span>
                </span>
              </div>
            </template>
          </el-table-column>
        </dwlist>
      </div>
    </template>
  </div>
</template>

<script>
import kSecondHeader from "../../../components/kHeader/kSecondHeader.vue";
import dwlist from "../../../components/dwList/dwlist.vue";
import utils from "../../../utils/util";
import Template from "../../Booking/template.vue";
export default {
  components: {
    kSecondHeader,
    dwlist,
    Template,
  },
  props: {
    defaultTab: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      settingList: [
        {
          menuName: "Quotation",
          extend: {
            extendS3: "Quotation",
          },
        },
        {
          menuName: "Recent booking",
          extend: {
            extendS3: "Recent booking",
          },
        },
        {
          menuName: "Template",
          extend: {
            extendS3: "Template",
          },
        },
      ],
      tab: null,
      quoQueryForm: {
        state: 20,
      },
      quotationTypeObj: {},
      temQueryForm: {},
      bookingQueryForm: {},
      utils: utils,
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleSelect(tab) {
      this.tab = tab;
    },
    query() {
      if (this.tab == "0") {
        this.$refs.quoList.query();
      }
      if (this.tab == "2") {
        this.$refs.quoTList.query();
      }
    },
    init() {
      this.getBaseData();
      this.tab = this.defaultTab;
    },
    getBaseData() {
      this.$session("userInfo").get((value) => {
        this.quoQueryForm.companyId = value.companyid;
        this.temQueryForm.companyId = value.companyid;
        this.bookingQueryForm.companyId = value.companyid;
      });
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
    },
    getAQuote(row, column, event) {
      var form = {
        quotationMainType: row.quotationMainType,
        quotationType: row.quotationType,
        fromType: row.fromType,
        fromCyPort: row.fromCyPort,
        fromCyPortName: row.fromCyPortName,
        fromDoorAddress: row.fromDoorAddress,
        fromDoorCountry: row.fromDoorCountry,
        fromDoorCity: row.fromDoorCity,
        fromDoorState: row.fromDoorState,
        fromDoorZipCode: row.fromDoorZipCode,
        toType: row.toType,
        toCyPort: row.toCyPort,
        toCyPortName: row.toCyPortName,
        toDoorAddress: row.toDoorAddress,
        toDoorCountry: row.toDoorCountry,
        toDoorCity: row.toDoorCity,
        toDoorState: row.toDoorState,
        toDoorZipCode: row.toDoorZipCode,
        fromDoorCountryName: row.fromDoorCountryName,
        fromDoorCityName: row.fromDoorCityName,
        fromDoorStateName: row.fromDoorStateName,
        toDoorCountryName: row.toDoorCountryName,
        toDoorCityName: row.toDoorCityName,
        toDoorStateName: row.toDoorStateName,
        cargoReadyDate: row.cargoReadyDate,
        deliveryDate: row.deliveryDate,
        containerDatas: row.containerDatas,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantitUnit: row.totalQuantitUnit,
        incotermsType: row.incotermsType,
        incotermsDataType: row.incotermsDataType,
        cargoType: row.cargoType,
        commodityHscodes: row.commodityHscodes,
        masks: row.masks,
        dangerousGoodsType: row.dangerousGoodsType,
        packingList: row.packingList,
        destinationBondFlag: row.destinationBondFlag,
        destinationBondType: row.destinationBondType,
        destinationWareFlag: row.destinationWareFlag,
        destinationWareServices: row.destinationWareServices,
        fromDoorPerson: row.fromDoorPerson,
        fromDoorTelephone: row.fromDoorTelephone,
        toDoorPerson: row.toDoorPerson,
        toDoorTelephone: row.toDoorTelephone,
      };
      // if (this.$i18n.locale == "en_us") {
      //   form.deliveryDate = utils.enDate_to_zhDate(form.deliveryDate);
      //   form.cargoReadyDate = utils.enDate_to_zhDate(form.cargoReadyDate);
      // }
      if (row.containerDatas !== "" && row.containerDatas) {
        form.containerDatas = JSON.parse(row.containerDatas);
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        form.dangerousGoodsType = JSON.parse(row.dangerousGoodsType);
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPickUpFlag = "1";
              break;
            case "1":
              form.originCustomsClearFlag = "1";
              break;
            case "2":
              form.originInsurFlag = "1";
              form.originInsurVlaue = item.insurVlaue;
              form.originInsurCurr = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWareFlag = "1";
              form.originWareServices = item.warehouse;
              break;
            case "6":
              form.destinationDelivFlag = "1";
              break;
            case "7":
              form.destinationCustomsClearFlag = "1";
              break;
            case "8":
              form.destinationBondFlag = "1";
              form.destinationBondType = item.bondType;
              break;
            case "9":
              form.destinationWareFlag = "1";
              form.destinationWareServices = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocFlag = "1";
              // this.$set(this.form, "originDocFlag", "1");
              break;

            default:
              break;
          }
        });
      }
      this.$emit("fillInQuo", { quotation: form, fillIn: true });
    },
    getABooking(row, column, event) {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.booking_api.bookingDetails, { id: row.id })
        .then((res) => {
          this.$store.commit("api/SET_LOADING", false);
          if (res.data.result == "true") {
            let result = res.data.bookingMain;
            var form = {
              quotationMainType: result.airSea,
              quotationType: result.bookingType,
              fromType: result.originPickUp,
              fromCyPort: result.originPort,
              fromDoorAddress: result.originAddress,
              fromDoorCountry: result.originCountryRegion,
              fromDoorCity: result.originCity,
              fromDoorState: result.originState,
              fromDoorZipCode: result.originZipCode,
              toType: result.destinationDelivery,
              toCyPort: result.destinationDischargePort,
              toDoorAddress: result.destinationAddress,
              toDoorCountry: result.destinationCountryRegion,
              toDoorCity: result.destinationCity,
              toDoorState: result.destinationState,
              toDoorZipCode: result.destinationZipCode,
              cargoReadyDate: result.cargoReadyDay,
              deliveryDate: result.destinationTargetDeliveryDate,
              containerDatas: result.containerType,
              totalVolume: result.totalVolume,
              totalVolumeUnit: result.totalVolumeUnit,
              totalWeight: result.totalWeight,
              totalWeightUnit: result.totalWeightUnit,
              totalQuantity: result.totalQuantity,
              totalQuantitUnit: result.totalQuantityUnit,
              incotermsType: result.incotermOne,
              incotermsDataType: result.incotermTwo,
              cargoType: result.cargoType,
              masks: result.notificationRemark,
              packingList: result.packings,

              originPickUpFlag: result.originPickUp,
              originCustomsClearFlag: result.originCustomerClearance,
              originInsurFlag: result.originInsurance,
              originInsurVlaue: result.originValue,
              originInsurCurr: result.originCurrency,
              originIsfFlag: result.originIsfFlag,
              originAmsFlag: result.originAmsFlag,
              originAciFlag: result.originAciFlag,
              originWareFlag: result.originWarehouse,
              originWareServices: result.originValueAddedSevice,
              destinationDelivFlag: result.destinationDelivery,
              destinationCustomsClearFlag: result.customerClearance,
              destinationBondFlag: result.destinationBound,
              destinationBondType: result.destinationBoundValue,
              destinationWareFlag: result.destinationWarehouse,
              destinationWareServices: result.destinationWarehouseValue,
            };
            // if (this.$i18n.locale == "en_us") {
            //   form.deliveryDate = utils.zhDate_to_enDate(form.deliveryDate);
            //   form.cargoReadyDate = utils.zhDate_to_enDate(form.cargoReadyDate);
            // }
            form.containerDatas = [];
            if (result.containerType !== "" && result.containerType) {
              let containerDatas = JSON.parse(
                res.data.bookingMain.containerType.replace("/", "")
              );
              containerDatas.forEach((item) => {
                form.containerDatas.push({
                  containerSize: item.containerTypOne,
                  count: item.containerTypTwo,
                });
              });
            }
            form.commodityHscodes = "";
            if (result.cargos && result.cargos.length > 0) {
              form.commodityHscodes = result.cargos[0].commodityValue;
            }
            form.dangerousGoodsType = [];
            if (result.totalBatteries == 1) {
              form.dangerousGoodsType.push("0");
            }
            if (result.hazardousMaterial == 1) {
              form.dangerousGoodsType.push("1");
            }
            if (result.totalOther == 1) {
              form.dangerousGoodsType.push("2");
            }
            if (result.lithiumBattery == 1) {
              form.dangerousGoodsType.push("3");
            }
            if (result.dangerousGoodsType !== "" && result.dangerousGoodsType) {
              form.dangerousGoodsType = JSON.parse(result.dangerousGoodsType);
            }

            this.$emit("fillInQuo", { quotation: form, fillIn: true });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          }
        });
    },
    Manage() {
      this.$emit("Manage", this.tab);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.qfillInList {
  width: 700px;
  min-height: 800px;
  overflow: auto !important;
  border-radius: 0 !important;
  padding: 0 2px;
  margin: auto;

  .el-table {
    border: 0;
  }
  .el-table--fit {
    border-right: 0 !important;
  }
  .el-table::before {
    height: 0;
  }
  .summry {
    font-size: 18px;
  }
  .CreateDate,
  .type {
    font-size: 14px;
    color: $fontColor2;
    line-height: 14px;
  }
  .fillRow {
    border-bottom: 0 !important;
    padding: 5px 0 !important;
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    .el-pagination__total {
      position: absolute;
      left: 20px;
    }
  }
  .kSecondHeader {
    margin-bottom: 15px;
  }
  .headeBbutton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .refresh {
      margin-right: 30px;
      line-height: 20px;
      color: $mainColor;
      font-size: 14px;
      cursor: pointer;
      .svg-icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        fill: $mainColor;
        stroke: $mainColor;
      }
    }
  }
  .templateList {
    padding-top: 20px;
    .item {
      padding: 10px 20px;
    }
    .item:hover {
      background-color: $background1;
    }
    .line1 {
      color: $background2;
      font-size: 18px;
    }
    .line2 {
      font-size: 14px;
      color: $fontColor2;
      padding-left: 20px;
      word-wrap: break-word;
      white-space: normal;
      word-break: break-all;
      display: inline-block;
    }
  }
  .port {
    .svg-icon {
      color: $background2;
      height: 10px !important;
      width: 10px !important;
      margin-top: -5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>